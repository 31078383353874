export const MenuEn = [
  {
    route: `/services/`,
    title: `Services`,
    children: [
      {
        route: null,
        title: `For Asset Owners And Investors`,
        heading: true,
      },
      {
        route: `/en/services/revenue-management/`,
        title: `Revenue Management`,
        heading: false,
      },
      {
        route: `/en/services/ppa-2/`,
        title: `PPA`,
        heading: false,
      },
      {
        route: `/en/services/advisory/`,
        title: `Investment Advisory`,
        heading: false,
      },
      {
        route: null,
        title: `For Power Consumers`,
        heading: true,
      },
      {
        route: `/en/services/renewable-power/`,
        title: `Renewable Power`,
        heading: false,
      },
      {
        route: `/en/services/corporate-ppa-for-power-consumers/`,
        title: `Corporate PPA`,
        heading: false,
      },
    ],
  },
  {
    route: `/en/reports/`,
    title: `Reports`,
    children: [],
    heading: false,
  },
  {
    route: `https://news.bodeckerpartners.com`,
    title: `News`,
    children: [],
    heading: false,
  },
  {
    route: `/en/about-us`,
    title: `About Us`,
    children: [],
    heading: false,
  },
]

export const topMenuEn = [
  {
    route: `/en/reports/`,
    title: `Reports`,
    children: [],
    heading: false,
  },
  {
    route: `https://news.bodeckerpartners.com`,
    title: `News`,
    children: [],
    heading: false,
  },
  {
    route: `/en/about-us`,
    title: `About Us`,
    children: [],
    heading: false,
  },
]
export const topMenuSe = [
  {
    route: `/rapporter/`,
    title: `Rapporter`,
    children: [],
    heading: false,
  },
  {
    route: `https://nyheter.bodeckerpartners.com/`,
    title: `Nyheter`,
    children: [],
    heading: false,
  },
  {
    route: `/om-oss`,
    title: `Om oss`,
    children: [],
    heading: false,
  },
]

export const services = [
  {
    route: null,
    title: `For Asset Owners And Investors`,
    heading: true,
  },
  {
    route: `/en/services/revenue-management/`,
    title: `Revenue Management`,
    heading: false,
  },
  {
    route: `/en/services/ppa-2/`,
    title: `PPA`,
    heading: false,
  },
  {
    route: `/en/services/advisory/`,
    title: `Investment Advisory`,
    heading: false,
  },
  {
    route: null,
    title: `For Power Consumers`,
    heading: true,
  },
  {
    route: `/en/services/renewable-power/`,
    title: `Renewable Power`,
    heading: false,
  },
  {
    route: `/en/services/corporate-ppa-for-power-consumers/`,
    title: `Corporate PPA`,
    heading: false,
  },
]
export const MenuSv = [
  {
    route: `/tjanster/`,
    title: `Tjänster`,
    children: [
      {
        route: null,
        title: `För Asset Owners och Investerare`,
        heading: true,
      },
      {
        route: `/en/services/ppa-2/`,
        title: `PPA`,
        heading: false,
      },
      {
        route: `/en/services/advisory/`,
        title: `Investment Advisory`,
        heading: false,
      },
      {
        route: `/en/services/renewable-power/`,
        title: `Renewable Power`,
        heading: false,
      },
      {
        route: `/en/services/corporate-ppa/`,
        title: `Corporate PPA`,
        heading: false,
      },
    ],
  },
  {
    route: `/en/reports/`,
    title: `Reports`,
    children: [],
    heading: false,
  },
  {
    route: `/en/news/`,
    title: `News`,
    children: [],
    heading: false,
  },
  {
    route: `/en/about`,
    title: `About Us`,
    children: [],
    heading: false,
  },
  {
    route: `/en/ctrm`,
    title: `CTRM Login`,
    children: [],
    heading: false,
  },
]
