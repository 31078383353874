import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 36.29 36.39"
      className="fill-current"
    >
      <path
        d="M273.64 238.56a1 1 0 00-.54-.54.88.88 0 00-.39-.08h-6.32a1 1 0 000 2h3.91l-18.86 18.86a1 1 0 000 1.41 1 1 0 001.41 0l18.86-18.86v3.92a1 1 0 102 0v-6.33a1 1 0 00-.07-.38z"
        className="cls-1"
        transform="translate(-237.43 -237.94)"
      ></path>
      <path
        d="M266.54 274.32h-28.11a1 1 0 01-1-1v-28.2a1 1 0 011-1h20.91a1 1 0 010 2h-19.91v26.2h26.11v-20a1 1 0 112 0v21a1 1 0 01-1 1z"
        className="cls-1"
        transform="translate(-237.43 -237.94)"
      ></path>
    </svg>
  )
}

export default Icon
