import React from "react"

export default function BpLogo() {
  return (
    <div className="w-full h-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 16.59 56.78"
        className="gray-900 fill-current"
      >
        <path
          d="M7.76 30.3H0v26.48h3.82v-9.42h3.94c5.2 0 8.83-3.51 8.83-8.53S13 30.3 7.76 30.3m0 13.24H3.82v-9.42h3.94c3.14 0 5 1.76 5 4.71 0 2.27-1.32 4.71-5 4.71"
          className="cls-1"
          data-name="Path 1"
        ></path>
        <path
          d="M16.59 18c0-5.1-3.55-8.53-8.83-8.53H3.82V0H0v26.48h7.76c5.2 0 8.83-3.51 8.83-8.53M3.82 13.24h3.94c3.14 0 5 1.76 5 4.71 0 2.28-1.32 4.71-5 4.71H3.82z"
          className="cls-1"
          data-name="Path 2"
        ></path>
      </svg>
    </div>
  )
}
